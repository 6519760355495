<template>
  <b-modal
    :id="modalId"
    hide-header
    hide-footer
    hide-header-close
    class="m-0"
    size="lg"
    header-class="my-second-class"
    body-bg-variant="gray-lighten"
  >
    <div>
      <b-row>
        <b-col
          align="left"
          class="modal-title-class text-left pl-4 pr-4"
          cols="12"
        >
          {{ $t('views.client.details.activities.right-content.level.title') }}
        </b-col>
        <b-col
          cols="12"
          class="pl-4 pr-4 mt-1"
        >
          <p class="level-description">
            {{ $t('views.client.details.activities.right-content.level.description') }}
          </p>
        </b-col>
      </b-row>
      <div class="border-bottom-grey-dark mb-2"/>
      <b-row>
        <b-col
          cols="12"
          class="pl-4 pr-4"
        >
          <levels
            v-if="mode === 'update'"
            :related-level="relatedLevel"
            :card-levels="cardLevels"
            class="mt-3"
            @on:card:selected="$emit('on:card:selected', $event)"
          />
          <create-or-update-level-form
            v-if="relatedLevel"
            :card-levels="cardLevels"
            :related-level="relatedLevel"
            @on:card:selected="$emit('on:card:selected', $event)"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col align="right">
          <d-button
            v-if="mode === 'update'"
            text="general.actions.delete"
            class="d-btn-sm d-btn btn d-btn-danger font-text-title ml-1 mr-2"
            @on:button-click="displayDeleteModal = !displayDeleteModal"
          />
          <d-button
            :class="isDisabled ? 'disabled' : ''"
            :icon="btnSaveLoading ? 'fa fa-spinner fa-spin' : ''"
            text="general.actions.save"
            class="d-btn-sm d-btn btn d-btn-primary-new font-text-title"
            @on:button-click="onSave"
          />
        </b-col>
      </b-row>
    </div>
    <d-modal
      :display="displayDeleteModal"
      :hide="hideDeleteModal"
      modal-id="delete-related-level"
      text="views.client.details.activities.right-content.level.delete-modal"
      title="views.client.details.activities.right-content.level.delete-title"
      @on:cancel="hideDeleteModal = !hideDeleteModal; btnDeleteLoading = false;"
      @on:confirm="onDelete"
    />
  </b-modal>
</template>
<script>

import RelatedActivities from "@/classes/doinsport/RelatedActivities";
import {
  deleteRelatedActivity,
  postRelatedActivity, putRelatedActivity
} from "@api/doinsport/services/user-client/related-activities/related-activities.api";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  data: () => ({
    btnSaveLoading: false,
    btnDeleteLoading: false,
    displayDeleteModal: false,
    hideDeleteModal: false,
  }),
  props: {
    relatedLevel: {
      type: Object,
      default: this
    },
    modalId: {
      type: String,
      default: 'default'
    },
    mode: {
      type: String,
      default: 'create'
    },
    cardLevels: {
      type: Array,
      default: () => []
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Levels: () => import('@views/client/details/activities/Levels'),
    LockForm: () => import('@form/client/LockForm'),
    CreateOrUpdateLevelForm: () => import('@form/client/level/CreateOrUpdateLevelForm'),
  },
  watch: {
    display: function () {
      this.$bvModal.show(this.modalId);
    }
  },
  computed: {
    isDisabled() {
      if (this.relatedLevel) {
        if (!this.relatedLevel.activity || !this.relatedLevel.activityLevels) {
          return true;
        } else {
          return this.btnSaveLoading;
        }
      }

      return true;
    }
  },
  methods: {
    hide() {
      this.$bvModal.hide(this.modalId);
    },

    onDelete() {
      if (!this.btnDeleteLoading) {
        this.btnDeleteLoading = true;

        deleteRelatedActivity(this.relatedLevel.id)
          .then(() => {
            const relatedLevelCard = this.cardLevels.find(el => el.userRelatedActivityId === this.relatedLevel.id);
            if (isNotUndefinedAndNotNull(relatedLevelCard)) {
              this.cardLevels.splice(this.cardLevels.indexOf(relatedLevelCard), 1);
              this.$emit('on:related-level:deleted');
            }
          })
          .finally(() => {
            this.btnDeleteLoading = false;
            this.hideDeleteModal = !this.hideDeleteModal;
          })
        ;

      }
    },
    onSave() {
      if (!this.isDisabled) {
        this.btnSaveLoading = true;

        this.createOrUpdateRelatedActivity(new RelatedActivities(this.relatedLevel, {serialize: true}))
          .then(() => {
            this.$emit('reload:related-activities');
            this.hide();
          })
          .finally(() => this.btnSaveLoading = false)
        ;
      }
    },
    createOrUpdateRelatedActivity(level) {
      return level.id ? putRelatedActivity(level.id, {activityLevels: level.activityLevels}) :  postRelatedActivity(level);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";

.level-description {
  text-align: left;
  font: normal normal normal 14px/21px Avenir;
  letter-spacing: 0.2px;
  color: #565555;
  opacity: 1;
}

.form-height {
  min-height: 150px
}
</style>
